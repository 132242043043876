import React, { useEffect } from "react";
import { useTable, useRowSelect } from "react-table";

//import { selectedUserState } from '../../states/selectedUserState';


const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)

export default function UserLoginListTable({ columns, data, setSelection }) {
  // Use the useTable Hook to send the columns and data to build the table
  //const user = useRecoilValue(selectedUserState);

  const onRecordClick = (record) => {
    // console.log(record);
    //  navigate(`/user/${userUid}/record/${record.uid}`);
  };

  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    selectedFlatRows,
    //state: { selectedRowIds },
    setHiddenColumns
  } = useTable({
    columns,
    data
  },
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        ...columns,
        // Let's make a column for selection
        //{
        //  id: 'selection',
        //  // The header can use the table's getToggleAllRowsSelectedProps method
        //  // to render a checkbox
        //  Header: ({ getToggleAllRowsSelectedProps }) => (
        //    <div>
        //      <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
        //    </div>
        //  ),
        //  // The cell can use the individual row's getToggleRowSelectedProps method
        //  // to the render a checkbox
        //  Cell: ({ row }) => (
        //    <div>
        //      <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        //    </div>
        //  ),
        //  className: "check-cell"
        //},
      ])
    });

  useEffect(() => {
    // Bubble up the selection to the parent component
    setSelection(selectedFlatRows.map((row) => row.original));    
  }, [setSelection, selectedFlatRows]);

  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <>
      <table {...getTableProps()} className="table table-striped table-sm records-table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} onClick={() => onRecordClick(row.original)}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps([
                    { className: cell.column.className }
                  ])}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}


 //<p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
 //     <pre>
 //       <code>
 //         {JSON.stringify(
 //           {
 //             selectedRowIds: selectedRowIds,
 //             'selectedFlatRows[].original': selectedFlatRows.map(
 //               d => d.original
 //             ),
 //           },
 //           null,
 //           2
 //         )}
 //       </code>
 //     </pre>