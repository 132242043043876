import { selectedUserState } from '../states/selectedUserState'
import { userListState } from '../states/userListState'
import { getRecoil, setRecoil } from "recoil-nexus";
import * as Consts from '../consts/userPermissions';
import api from "./api";

class CurrentUserService {
  forceUpdateUsersFromServer() {
    api
      .get(`/users`)
      .then((response) => {
        this.updateUserList(response.data);
      });
  }

  updateUserList(usersData) {
    //console.log('updating user list')
    setRecoil(userListState, usersData);
    let user = getRecoil(selectedUserState);
    //console.log('updating user list - user', user);
    this.setCurrentUserFromList(usersData, user?.uid);
    //if (user && user.uid == null) {
    //  this.setCurrentUserFromList(usersData, null);
    //} else {

    //}

  }

  getUserListForSideMenu() {
    //console.log("s")
    let userData = getRecoil(userListState);
    let userList = [];
    function convertUser(u) {
      return {
        key: u.uid,
        label: u.displayName,
        itemUid: u.uid,
        permissions: u.permissions,
        isGroup: false,
        nodes: []
      }
    };

    for (const user of userData.users)
      userList.push(convertUser(user));

    for (const group of userData.groups) {
      let newGroup = convertUser(group);
      newGroup.isGroup = true;
      for (const user of group.users)
        newGroup.nodes.push(convertUser(user));

      userList.push(newGroup);
    }

    return userList;
  }

  getInitialSelectionForSideMenu() {
    let userData = getRecoil(userListState);
    let user = getRecoil(selectedUserState);
    if (userData.users.length > 0 && user?.uid) {

      let found = userData.users.find(x => x.uid === user.uid);
      if (found)
        return { selected: found.uid, opened: null };

      for (const group of userData.groups) {
        let found = group.users.find(x => x.uid === user.uid);
        if (found)
          return {
            selected: `${group.uid}/${found.uid}`, opened: `${group.uid}`};
      }

      return { selected: null};
    }

    return { selected: null };
  }

  findUserByUidOrDefault(userData, userUid) {
    let user = null;
    if (!userUid) {
      user = userData.mainUser;
   //   console.log('default user selected: ', user);
    } else {
      user = userData.users.find(x => x.uid === userUid);
      if (!user) {
        for (const group of userData.groups) {
          user = group.users.find(x => x.uid === userUid);
          if (user)
            break;
        }
      }
    }

    return user;
  }

  setCurrentUserFromList(userData, userUid) {
    //console.log('Setting user by uid: ', userUid);
    let user = this.findUserByUidOrDefault(userData, userUid);

    if (user) {
      setRecoil(selectedUserState, user);
      //console.log('userObject set: ', user);
    }
    else {
      setRecoil(selectedUserState, { uid: userUid });
      //console.log('user uid set: ', userUid);
    }
  }

  setCurrentUser(userUid) {
   //console.log('setting current user ', userUid);
    let userData = getRecoil(userListState);
    //console.log('userData', userData);
    //if (userData.users.length > 0)
    this.setCurrentUserFromList(userData, userUid);
  }  

  hasPermission(user, permission) {
    return (user?.permissions & permission) > 0;
  }
  
  hasCreateRecordPerimssion() {
    let user = getRecoil(selectedUserState);
    //console.log('checking create permission: ', user);
    return (user?.permissions & Consts.USER_PERMISSION_CREATE_RECORD) > 0;
  }

  hasDeleteRecordPerimssion() {
    let user = getRecoil(selectedUserState);
    return (user?.permissions & Consts.USER_PERMISSION_DELETE_RECORD) > 0;
  }
  
}
export default new CurrentUserService();