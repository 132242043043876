import {  useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import UserLoginPermissions from "../../services/userLogin.permissions.service";

import { MenuListUser } from './Components/MenuListUser'
import { MenuListExpert } from './Components/MenuListExpert'
import { MenuListMetrology } from './Components/MenuListMetrology'
import { MenuListAdmin } from './Components/MenuListAdmin'


export { SideMenuMain };
function SideMenuMain({ closeOffcanvas }) {
  const location = useLocation();
  const path = location.pathname;

  let { consultationUid } = useParams();
  
  useEffect(() => {
    
  });

  const closeOffcanvasAction = () => {
    if (closeOffcanvas) {
      let closeBtn = document.getElementById('closeOffcanvasButton'); //dirty hack to find a button and close to avoid integrating bootstrap.js
      if (closeBtn) closeBtn.click();
    }
  };  
    
  const isExpertViewMode = () => consultationUid || path.includes(`/consultations/expert/`) || path.includes(`/metrology/devices`) || path.includes(`/admin/`);

  return (
    <>  
      <MenuListUser closeOffcanvasAction={closeOffcanvasAction} isExpertViewMode={isExpertViewMode()} />

      {UserLoginPermissions.isExpert() && (
        <MenuListExpert closeOffcanvasAction={closeOffcanvasAction} isExpertViewMode={isExpertViewMode()} />
      )}


      {UserLoginPermissions.canDoMetrology() && (
        <MenuListMetrology closeOffcanvasAction={closeOffcanvasAction} />
      )}

      {UserLoginPermissions.isAdmin() && (
        <MenuListAdmin closeOffcanvasAction={closeOffcanvasAction} />
       )}

    </>
  )
}