import React, { useState, useEffect, useRef } from 'react';
import api from "../../../services/api";
import RecordsListTable from "./RecordsListTable";
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Tabs, Tab, Alert } from 'react-bootstrap';

import { useParams } from 'react-router-dom';

import UserDiary from '../Diary/UserDiary'
import MoveRecordsModal from './MoveRecordsModal'

import { Container } from 'reactstrap';

import Loading from '../Loading'
import { useNavigate } from 'react-router-dom';
import ModalConfirm from '../ModalConfirm';

import { useToastContext } from "../../../toasts/toast-context";

export default function UserViewRecords({ getRecordListUrl, getPageUserRecordUrlTemplate, allowMoveRecords, allowDeleteRecords, allowChangeEcg12 }) {  
  const { t } = useTranslation();
  let { userUid, recordUid } = useParams();

  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(true);  
  const [isErrorLoading, setIsErrorLoading] = useState(false);

  const { showErrorToastMessage } = useToastContext();

  const [tableToDisplay, setTableToDisplay] = useState([]);
  
  const [resetRowSelection, setResetRowSelection] = useState(0);

  const [movingRecords, setMovingRecords] = useState({ isMoving: false, records: [] });

  const [deletingRecords, setDeletingRecords] = useState({ isDeleting: false, records: [] });

  let navigate = useNavigate();


  useEffect(() => {
    function loadRecordList() {
      api
        .get(getRecordListUrl)
        .then((response) => {
          setResponse(response.data);
          setTableToDisplay(response.data.items);
          setLoading(false);
          setIsErrorLoading(false);
        }, (error) => {
          if (error?.response?.data?.errorCode === 105) {
            showErrorToastMessage(t('messages.noViewPagePermission'));
            setLoading(false);
            setIsErrorLoading(true);
          };
        });
    };

    loadRecordList();

    setResetRowSelection(resetRowSelection + 1);

  }, [userUid]);

  const onTableRecordClick = function (row) {
    if (getPageUserRecordUrlTemplate) {
      navigate(getPageUserRecordUrlTemplate(row.userUid, row.uid));
    }
  };

  const onDeleteRecordClick = (record) => {
    setDeletingRecords({ isDeleting: true, records: [record.uid] });    
  }

  const onDeleteRecordConfirmClick = () => {
    let selectedUids = deletingRecords.records;
    api
      .delete(getRecordListUrl, { data: { selectedUids: selectedUids } })
      .then((response) => {
        window.location.reload(false);
      });
  }

  const onMoveRecordClick = (record) => {
    let selectedUids = [record.uid];
    setMovingRecords({ isMoving: true, records: selectedUids });
  };

  const onChangeEcg12 = (record, callbackResult) => {    
    api
      .post(`${getRecordListUrl}/${record.uid}/ecg12`, { showInEcg12: record.isShowInEcg12 })
      .then((response) => {              
        if (callbackResult)
          callbackResult(response.data);
      });
  }

  const onSuccessMoveCallback = () => {

    //refresh page??
    //removing from list is not enough - need to recalc the table and reset selection ticks.
    setTableToDisplay(tableToDisplay.filter(x => !movingRecords.records.includes(x.uid)));
    setResetRowSelection(resetRowSelection + 1);
  };

  if (loading)
    return <Loading />;

  if (isErrorLoading)
    return <Alert key="danger" variant="danger">{t('messages.noViewPagePermission')}</Alert>;

  return (
    <>          
      <Row>
        <Col>
          <RecordsListTable
            data={tableToDisplay}
            onRecordClick={onTableRecordClick}
            resetSelection={resetRowSelection}
            onMoveRecordClick={allowMoveRecords ? onMoveRecordClick : null }
            onDeleteRecordClick={allowDeleteRecords ? onDeleteRecordClick : null}
            onEditEcg12={allowChangeEcg12 ? onChangeEcg12 : null}
          />
        </Col>
      </Row>


      {movingRecords.isMoving &&
        <MoveRecordsModal show={movingRecords.isMoving}
          onHide={() => setMovingRecords({ isMoving: false })}
          records={movingRecords.records}
          onSuccessCallback={onSuccessMoveCallback}          
      />
      }

    {deletingRecords.isDeleting &&
      <ModalConfirm
        show={deletingRecords.isDeleting}
        onHide={() => setDeletingRecords({isDeleting: false})}
        header={t('recordList.deleteRecord.title')}
        body={t('recordList.deleteRecord.body')}
        buttonConfirmText={t('recordList.deleteRecord.confirmButton')}
        buttonCancelText={t('recordList.deleteRecord.cancelButton')}
        onConfirmClick={() => onDeleteRecordConfirmClick(recordUid)}
        />
      }
    </>
  );  
}

