import { useEffect } from 'react';
import styles from './SideMenu.module.scss';
import { SideMenuMain } from './SideMenuMain';
import { SideMenuSettings } from './SideMenuSettings';
import { SideMenuAbout } from './SideMenuAbout';

export { SideMenu };

function SideMenu({ closeOffcanvas, menuType }) {  

  useEffect(() => {
    console.log(menuType);
  });

  return (
    <>
      <div id="sidebarMenu" className={`${styles.sidebar}`}>    
        {menuType === MenuTypes.Main && <SideMenuMain closeOffcanvas={closeOffcanvas} />}
        {menuType === MenuTypes.Settings && <SideMenuSettings closeOffcanvas={closeOffcanvas} />}
        {menuType === MenuTypes.About && <SideMenuAbout closeOffcanvas={closeOffcanvas} />}        
      </div>     
    </>
    )

}

export const MenuTypes = {
  Main: 10,
  Settings: 20,
  About: 30
};