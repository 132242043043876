import {
  flexRender,
} from "@tanstack/react-table";

export default function TableHeader({ table, styles, headerRowStyle, headerTextStyle, innerCellStyle }) {
  if (styles) {
    if (!headerRowStyle) headerRowStyle = styles.headerRow;
    if (!headerTextStyle) headerTextStyle = styles.headerText;
    if (!innerCellStyle) innerCellStyle = styles.innerCell;
  }
  return (
    <>
      {table.getHeaderGroups().map(headerGroup => {
        return (
          <div key={headerGroup.id} className={headerRowStyle}>
            {headerGroup.headers.map(header => {
              return (
                <div key={header.id} className={`${headerTextStyle} ${header.column.columnDef.className ?? ''}`} >
                  {header.isPlaceholder ? null : (
                    <div className={innerCellStyle}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {header.column.getCanFilter() ? (
                        <div>
                          {/*<Filter column={header.column} table={table} />*/}
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
              )
            })}
          </div>)
      })
      }
    </>
  )
}