import React, { useEffect } from "react";

import AuthService from "../../services/auth.service";

//import { useSetRecoilState } from 'recoil';
//import { userListState } from '../../states/userListState'

import CurrentUserService from '../../services/currentUser.service';

import api from "../../services/api";


function UserListLoader() {
  //const setUserList = useSetRecoilState(userListState);

  useEffect(() => {
    function loadUserList() {
      CurrentUserService.forceUpdateUsersFromServer();      
    };

    if (AuthService.shouldShowNavBars())
      loadUserList();

  }, []);


  return (
    <>
    </>
    );
}

export default UserListLoader;