import React, { useState, useEffect, useRef } from 'react';

import api from "../../../services/api";
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap'
import TokenService from "../../../services/token.service";
import { EcgWebSocketReceiver } from './EcgWebSocketReceiver'
import { useTranslation } from 'react-i18next';

export function BaseEcgView({ ecgData, realtimeDataUrl }) {
  const { t } = useTranslation();
  const chartComponent = useRef(null);
  const [realtimeViewMode, setRealtimeViewMode] = useState(0); //0 - continuous, 1 - refresh
  const realtimeModeRef = useRef(0);
  const isChartInitialized = useRef(false);
  const currentTimeAdjustment = useRef(0);
  const ecgChartDataArray = useRef([]);
  const [isOnlineTransmission, setOnlineTransmission] = useState(false);

  //let [hoverData, setHoverData] = useState();

  const DEFAULT_SHOW_MS = 10000;

  useEffect(() => {

    //temp code to print values
    //if (ecgData != null)
    //  console.log(ecgData.map(x => x[1]).join(','));
    //console.log('useEffect', ecgData);

    console.log('useEffect');

    if (ecgData.length > 0) {
      initChart();
    }

    if (realtimeDataUrl) {
      chartComponent.current.chart.xAxis[0].setExtremes(
        chartComponent.current.chart.xAxis[0].dataMax - DEFAULT_SHOW_MS, chartComponent.current.chart.xAxis[0].dataMax
      );
    };
    //if (chartComponent) {
    //  console.log(chartComponent.current.chart.rangeSelector.buttons[1]);
    //  //chartComponent.current.chart.rangeSelector.buttons[1].element.click();
    //}    
  }, [ecgData]);

  const initChart = function () {
    if (!isChartInitialized.current) {

      if (ecgData.length > 0)
        ecgData.map(x => ecgChartDataArray.current.push([x[0], x[1]]));

      console.log('ecgChartDataArray: ', ecgChartDataArray.current, 'ecgData: ', ecgData);
      
      setChartOptions({
        series: [
          {
            data: ecgChartDataArray.current,
            name: '',
            color: 'red'
          },
        ],
      });

      isChartInitialized.current = true;
    }

  };

  const drawVerticalLine = (x) => {
    let chart = chartComponent.current.chart;
    //console.log(chart.xAxis);
    chart.xAxis[0].update({
      plotLines: [{
        color: '#FF0000', // Red
        width: 2,
        value: x // Position, you'll have to translate this to the values on your x axis
      }]
    });

  };

  const newDataReceived = (data) => {
    initChart();
    //console.log('mode: ', realtimeModeRef.current);
    //console.log('ecgChartDataArray: ', ecgChartDataArray.current);
    //console.log('data: ', data);

    setOnlineTransmission(true);

    let chart = chartComponent.current.chart;
    switch (realtimeModeRef.current) {
      case 0: //continuous
        {
          for (let i = 0; i < data.length; i++) {
            chart.series[0].addPoint(data[i], false);
            //ecgChartDataArray.current.push([data[i][0], data[i][1]]);
          }
          chart.redraw({ duration: 110});
          break;
        }
      case 1: //refresh
        {                  
          let array_chunks = [];
          
          let i = 0;
          let tempTimeAdjustment = currentTimeAdjustment.current;
          while (i < data.length) {
            let newPortion = 0;
            let startTime = data[i][0] - tempTimeAdjustment;
            if (startTime >= DEFAULT_SHOW_MS) { tempTimeAdjustment = data[i][0]; startTime = 0; }
            while (((i + newPortion) < data.length) && (data[i + newPortion][0] - tempTimeAdjustment < DEFAULT_SHOW_MS))
              newPortion++;

            let chunk = data.slice(i, i + newPortion);
            array_chunks.push(chunk);
            i += newPortion;
            if (newPortion === 0) break;
          }
       
          array_chunks.map(chunk => {
            let start = chunk[0][0] - currentTimeAdjustment.current;
            if (start >= DEFAULT_SHOW_MS) { currentTimeAdjustment.current = chunk[0][0]; start = 0; }

            let end = chunk[chunk.length - 1][0] - currentTimeAdjustment.current;
            //if (end <= DEFAULT_SHOW_MS) {
              let cutStart = ecgChartDataArray.current.findIndex(x => x[0] >= start);
              if (cutStart < 0) cutStart = ecgChartDataArray.current.length;
              let cutEnd = ecgChartDataArray.current.findIndex(x => x[0] > end);
              if (cutEnd < 0) cutEnd = ecgChartDataArray.current.length;

              console.log('currentTimeAdjustment.current: ', currentTimeAdjustment.current, 'cutStart: ', cutStart, 'cutEnd: ', cutEnd);

              let dataToInsert = [];
              chunk.map(x => dataToInsert.push([x[0] - currentTimeAdjustment.current, x[1]]));

              console.log('dataToInsert: ', dataToInsert);

              ecgChartDataArray.current.splice(cutStart, cutEnd - cutStart, ...dataToInsert);

              drawVerticalLine(chunk[chunk.length - 1][0] - currentTimeAdjustment.current);
            //} 

          });

          console.log('result: ', ecgChartDataArray.current);

          chart.series[0].setData(ecgChartDataArray.current, true, false, false);
          chart.redraw(false);
          break;
        }
      default:
        throw 'Not implemented yet';
    }    
  };

  const finalize = () => {
    setOnlineTransmission(false);
    console.log('Finished');
  };

  const setContinuousMode = () => {
    setRealtimeViewMode(0);
    realtimeModeRef.current = 0;
    ecgChartDataArray.current = [];
    let chart = chartComponent.current.chart;    

    chart.series[0].setData(ecgChartDataArray.current, true, false, false);
    chart.xAxis[0].setExtremes(0, DEFAULT_SHOW_MS);
  };

  const setRefreshMode = () => {
    setRealtimeViewMode(1);
    realtimeModeRef.current = 1;    

    let chart = chartComponent.current.chart;    
    chart.xAxis[0].setExtremes(0, DEFAULT_SHOW_MS);

    let ecgDataArray = ecgChartDataArray.current;

    let latestXValue = ecgDataArray.length > 0 ? ecgDataArray[ecgDataArray.length - 1][0] : 0;

    let lastPoints = [];
    let lastIndex = ecgDataArray.length - 1;
    let lastXvalueToTake = latestXValue - DEFAULT_SHOW_MS;
    if (lastXvalueToTake < 0) lastXvalueToTake = 0;
    
    while (lastIndex > 0 && ecgDataArray[lastIndex][0] > lastXvalueToTake) {
      let point = [ecgDataArray[lastIndex][0], ecgDataArray[lastIndex][1]];
      lastPoints.push(point);
      lastIndex--;
    }
    lastPoints = lastPoints.reverse();

    let adj = lastPoints.length > 0 ? lastPoints[0][0] : 0;
    currentTimeAdjustment.current = adj;

    lastPoints.map(el => el[0] = el[0] - adj);

    console.log(lastPoints);

    ecgChartDataArray.current = lastPoints;

    chart.series[0].setData(lastPoints);
  };

  let [chartOptions, setChartOptions] = useState({
    // To avoid unnecessary update keep all options in the state.
    chart: {
      height: '400px',
      events: {
        load: function (x) {
          this.xAxis[0].setExtremes(0, 10000); //show first 10 seconds

          //console.log('load function', listenToOnlineData, this.xAxis[0].dataMax);
          //if (listenToOnlineData) {
          //  //console.log(this.xAxis[0]);
          //  this.xAxis[0].setExtremes(
          //    this.xAxis[0].dataMax - 10000, this.xAxis[0].dataMax
          //  );
          //} else {
          //  this.xAxis[0].setExtremes(0, 10000);
          //}
        }
      }
      //events: {
      //  render: function () {

      //    var axes = this.axes,
      //      showMinorTicks = true;

      //    // find if minor ticks are present on both axes
      //    axes.forEach(function (a) {
      //      console.log(a.minorTicks);

      //      if (Object.keys(a.minorTicks).length === 0) {
      //        showMinorTicks = false;
      //      }
      //    });

      //    // hide/show ticks
      //    axes.forEach(function (a) {
      //      for (var key in a.minorTicks) {
      //        var mt = a.minorTicks[key].gridLine;
      //        showMinorTicks ? mt.show() : mt.hide();
      //      }
      //    });
      //  }
      //}
    },
    xAxis: {
      tickInterval: 200,
      minorTicks: true,
      minorTickInterval: 40,
      gridLineWidth: 1,
      gridLineColor: '#7cb5ec',
      //gridLineColor: 'red',

      maxRange: DEFAULT_SHOW_MS + 1,

      dateTimeLabelFormats: {
        millisecond: '%M:%S.%L',
        second: '%M:%S.%L',
        minute: '%M:%S.%L',
        hour: '%M:%S.%L',
        day: '%M:%S.%L',
        week: '%M:%S.%L',
        month: '%M:%S.%L',
        year: '%Y'
      },
      allowDecimals: true,
      labels: {
        step: 2
      }
    },
    yAxis: {
      tickInterval: 0.5,
      minorTicks: true,
      minorTickInterval: 0.1,
      gridLineWidth: 1,
      gridLineColor: '#7cb5ec',
      //gridLineColor: 'red',
      zoomEnabled: false,
      min: -3,
      max: 3
    },
    series: [

    ],
    rangeSelector: {
      inputEnabled: false,
      selected: 1,
      buttons: [{
        type: 'second',
        count: 1,
        text: '1s'
      }, {
        type: 'second',
        count: 10,
        text: '10s'
        }
        //, {
        //type: 'minute',
        //count: 1,
        //text: '1m'
        //}
        //,{
        //type: 'all',
        //text: 'All'
        //}
      ],
      buttonTheme: {
        width: 50
      },
    },
    navigator: {
      series: {
        type: 'line',
      },
      xAxis: {
        dateTimeLabelFormats: {
          millisecond: '%M:%S.%L',
          second: '%M:%S.%L',
          minute: '%M:%S.%L',
          hour: '%M:%S.%L',
          day: '%M:%S.%L',
          week: '%M:%S.%L',
          month: '%M:%S.%L',
          year: '%M:%S.%L'
        },
        labels: {
          //format: "{value:.1f}"              
        }
      }
    },
    tooltip: {
      valueDecimals: 2,
      valueSuffix: 'mV',
      split: true,
      shared: false,
      //dateTimeLabelFormats: {
      //  millisecond: '%M:%S.%L',
      //  second: '%M:%S.%L',
      //  minute: '%M:%S.%L',
      //  hour: '%M:%S.%L',
      //  day: '%M:%S.%L',
      //  week: '%M:%S.%L',
      //  month: '%M:%S.%L',
      //  year: '%M:%S.%L'
      //},
      xDateFormat: '%M:%S.%L'
      //xDateFormat: '%H-%M-%S',
    },
    plotOptions: {
      series: {
        point: {
          events: {
            //mouseOver: function () { setHoverData(this.category); }
          }
        },
        dataGrouping: {
          enabled: false
        }
      },
      line: {
        dataGrouping: {
          dateTimeLabelFormats: {
            millisecond: [
              '%M:%S.%L', '%M:%S.%L', ''
            ],
            second: ['%M:%S.%L', '%M:%S.%L', ''],
            minute: ['%M:%S.%L', '%M:%S.%L', ''],
            hour: ['%M:%S.%L', '%M:%S.%L', ''],
            day: ['%M:%S.%L', '%M:%S.%L', ''],
            week: ['%M:%S.%L', '%M:%S.%L', ''],
            month: ['%M:%S.%L', '%M:%S.%L', ''],
            year: ['%M:%S.%L', '%M:%S.%L', ''],
          }
        }
      }
    }
  });

  return (
    <>
      {
        realtimeDataUrl &&
        <>{t('recordView.realtime.viewMode.header') + ':  '}
          <ButtonGroup className="pull-right">
            <ToggleButton key={"0"} id={"id-0"} type="radio" variant='outline-warning' name="radio" value={0} checked={realtimeViewMode === 0} onChange={setContinuousMode}>
              {t('recordView.realtime.viewMode.continuous')}
            </ToggleButton>
            <ToggleButton key={"1"} id={"id-1"} type="radio" variant='outline-warning' name="radio" value={1} checked={realtimeViewMode === 1} onChange={setRefreshMode}>
              {t('recordView.realtime.viewMode.overwrite')}
            </ToggleButton>         
          </ButtonGroup>

          <span className="float-end">{t('recordView.realtime.state.header')}: {isOnlineTransmission && <span><i className="bi bi-circle-fill" style={{ "color": "limegreen" }} ></i> {t('recordView.realtime.state.online')}</span>}{!isOnlineTransmission && <span>{t('recordView.realtime.state.offline')}</span> }</span>
      </>
      }

      <HighchartsReact
        ref={chartComponent}
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={chartOptions}
      />

      {/* <h3 style={{ display: 'none' }}>Hovering over {hoverData}</h3> */}
      {realtimeDataUrl && <EcgWebSocketReceiver realtimeDataUrl={realtimeDataUrl} receiveNewDataCallback={newDataReceived} finalizeCallback={finalize} />}
    </>
  )


}