import React, { useState, useEffect, useRef } from 'react';
import api from "../../../services/api";
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { BaseEcgView } from '../Ecg/BaseEcgView';
import { BaseMultipleChartsView } from '../Ecg/BaseMultipleChartsView'
import { dateFormatter } from '../../../utils/dateFormatter'
import {recordLengthFormatter} from '../../../utils/recordLengthFormatter'

import RequestConsultationConfirmModal from '../../Consultation/RequestConsultationConfirmModal'
import ViewConsultationList from '../Consultation/ViewConsultationList'

import Loading from '../Loading'

import { AllProcessedData } from '../Processed/AllProcessedData'

import { UserViewOneRecordInfo } from './UserViewOneRecordInfo'

import styles from './UserViewOneRecord.module.scss';


export default function UserViewOneRecord({ userUid, recordUid, baseOneRecordUrl, allowRequestConsultation, allowViewConsultation }) {
  const { t } = useTranslation();
    
  const [loading, setLoading] = useState(true);

  const [receivedData, setReceivedData] = useState({ data: [], realtimeNow: false, dataList: [], chartDataTypes: [] });  
  const [calculatedDataUrl, setCalculatedDataUrl] = useState(null);    

  const [updatedMaxLengthSeconds, setUpdatedMaxLengthSeconds] = useState(0);

  const processedDataComponentRef = useRef(null);
  const recordInfoComponentRef = useRef(null);

  const loadOneRecord = (currentTimeType) => {
    api
      .get(`${baseOneRecordUrl}/${recordUid}?timeType=${currentTimeType}`)
      .then((response) => {
        setReceivedData(response.data);
        setLoading(false);
      }, (error) => {
        setLoading(false);
      });
  };

  useEffect(() => {   
    loadOneRecord(0);
    setCalculatedDataUrl(`${baseOneRecordUrl}/${recordUid}/calculated`);
  }, [recordUid, userUid]);

  const setTimeType = (timeType) => {
    loadOneRecord(timeType);
  };


  const onShowCalculatedDataClick = () => {
    if(!calculatedDataUrl)
      setCalculatedDataUrl(`${baseOneRecordUrl}/${recordUid}/calculated`);
  };

  if (loading)
    return <Loading />;

  const RecordDescriptionLine = ({ header, text }) => {
    return <Col lg><b>{header}:</b> {text}</Col>
  }

  const processedDataReceived = (processedData) => {
    //setUpdatedMaxLengthSeconds(lastProcessedSecond);
    processedDataComponentRef.current?.setLastProcessedTime(processedData?.lastProcessedSecond);
    recordInfoComponentRef.current?.setProcessedData(processedData);
  }
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        {/*<h1 className="h2">{response.user.displayName}</h1>    */}
      </div>
      {loading ? <Loading /> :
        <>
          {allowRequestConsultation &&
            <Row className="mb-4">
              <Col>
                <RequestConsultationConfirmModal recordUid={recordUid} />
              </Col>
            </Row>
          }
         
          <UserViewOneRecordInfo ref={recordInfoComponentRef} description={receivedData.description} />         
         
          <>
            <Row className={`${styles.bt} mt-4`}>
              <Col>
                {receivedData.data && <BaseEcgView ecgData={receivedData.data} realtimeDataUrl={receivedData.realtimeNow ? `/api/v1/front/user/records/${recordUid}/listen/ws` : null} />}
                {receivedData.dataList && <BaseMultipleChartsView
                  dataList={receivedData.dataList}
                  chartDataTypes={receivedData.chartDataTypes}
                  realtimeDataUrl={receivedData.realtimeNow ? `/api/v1/front/user/records/${recordUid}/listen/ws` : null}
                  asyncDataLoadUrl={`${baseOneRecordUrl}/${recordUid}/partial`}
                  timeTypeChangeCallback={(x) => { setTimeType(x); }}
                  processedDataReceived={(x) => { processedDataReceived(x); }}
                />}

              </Col>
            </Row>
            <Row className="mt-5">
              <Col >
                <AllProcessedData ref={processedDataComponentRef} requestUrl={calculatedDataUrl} updatedMaxLengthSeconds={updatedMaxLengthSeconds}></AllProcessedData>                      

                {/*<Accordion flush>*/}
                {/*  <Accordion.Item eventKey="0" style={{ backgroundColor: 'lavender' }} >*/}
                {/*  */}{/*  <Accordion.Header </Accordion.Header>*/}
                {/*    <Accordion.Button onClick={onShowCalculatedDataClick} style={{ backgroundColor: 'lavender' }}>{t('temp.showCalculated')}</Accordion.Button>*/}
                {/*    <Accordion.Body>*/}
                {/*      <AllProcessedData requestUrl={calculatedDataUrl}></AllProcessedData>                      */}
                {/*    </Accordion.Body>*/}
                {/*  </Accordion.Item>                  */}
                {/*</Accordion>*/}
              </Col>
            </Row>
            {allowViewConsultation &&
              <Row className="mt-5">
                <Col>
                  <ViewConsultationList recordUid={recordUid} allowRequestConsultation={allowRequestConsultation} />
                </Col>
              </Row>}
          </>
        </>
      }
    </>
  );
}

