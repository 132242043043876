import React, { useState, useEffect, useMemo } from 'react';
import { Button, Modal, Alert, Col, Row, Stack, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import api from "../../../services/api";
import CurrentUser from "../../../services/currentUser.service";

import Loading from '../Loading'
import { dateFormatter } from '../../../utils/dateFormatter'

import ProfileFieldString from './ProfileFieldString'
import ProfileFieldDate from './ProfileFieldDate'
import ProfileFieldOptions from './ProfileFieldOptions'
import ProfileFieldNumber from './ProfileFieldNumber'
import ProfileFieldTextArea from './ProfileFieldTextArea';

import styles from './UserProfile.module.scss'

import { SaveButtonSeparateRow } from '../CommonForm/CommonForm';
export default function UserProfile({ profileBaseUrl, canEdit }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);  
  const [data, setData] = useState(null);  
  const [isEditMode, setIsEditMode] = useState(false);

  const FIELDS = {
    FirstName: 10,
    LastName: 20,
    DOB: 30,
    Age: 40,
    Sex: 50,
    Email: 60,

    String: 100,
    TextAreaThreeLines: 110,
    TextAreaTwoLines: 111,
    Integer: 200,
    Options: 300
  };



  const loadData = () => {
    api
      .get(profileBaseUrl)
      .then((response) => {
        setData(response.data);        
        setLoading(false);            
      });

    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);
  
  const saveChanges = () => {
    if (canEdit) {
      setIsEditMode(false);
      setLoading(true);

      let model = { questions: [] };
      data.items.map(line => {
        let answer = [FIELDS.Sex, FIELDS.Options].includes(line.questionType) ? line.options.selectedOptionUid : line.value;
        model.questions.push({
          qId: line.uid,
          value: answer
        })
      });

      api.post(profileBaseUrl, model).then(response => {
        setData(response.data);
        setLoading(false);
        CurrentUser.forceUpdateUsersFromServer();
      },
        (error) => {
          setLoading(false);
        });
    };    
  };

  const Line = (props) => <Row className={!isEditMode ? styles.line : ``}>{props.children}</Row>;
  const Bold = (props) => <span className={styles.header}>{props.children}</span>;

  if (loading)
    return <Loading />;

  return (
    <>    
      <Container fluid>
        {canEdit && !isEditMode &&
          <Row className={styles.buttonRow}>
            <Col xxl={12} xl={12} lg={12} md={12} className="d-flex justify-content-end">                   
              <Button variant="primary" className={styles.wideBtn} onClick={() => { if (canEdit) setIsEditMode(true) }}>{t('profile.editButton')}</Button>                       
            </Col>
          </Row>
        }

        {data && data.items && data.items.map(line => {
          return (
            <React.Fragment key={line.uid}>{[FIELDS.FirstName, FIELDS.LastName, FIELDS.Email, FIELDS.String].includes(line.questionType) &&
              (<Line>
                  <ProfileFieldString isEditMode={isEditMode} profileLine={line} setValue={(v) => line.value = v} />
              </Line>
              )}

              {FIELDS.DOB === line.questionType && (
                <><Line>
                  <ProfileFieldDate isEditMode={isEditMode} profileLine={line} setValue={(v) => line.value = v} />
                
                  {!isEditMode &&
                    <Col>{t('profile.age')}: <Bold>{data.fullYears}</Bold></Col>}
                </Line>
                </>
              )}

              {FIELDS.TextAreaThreeLines === line.questionType && (
                <Line>
                  <ProfileFieldTextArea isEditMode={isEditMode} profileLine={line} setValue={(v) => line.value = v} lineCount={3} />
                </Line>
              )}

              {FIELDS.TextAreaTwoLines === line.questionType && (
                <Line>
                  <ProfileFieldTextArea isEditMode={isEditMode} profileLine={line} setValue={(v) => line.value = v} lineCount={2} />
                </Line>
              )}

              {FIELDS.Integer === line.questionType && (
                <Line>
                  <ProfileFieldNumber isEditMode={isEditMode} profileLine={line} setValue={(v) => line.value = v} />
                </Line>
              )}

              {[FIELDS.Sex, FIELDS.Options].includes(line.questionType) &&
                (<Line>
                <ProfileFieldOptions isEditMode={isEditMode} profileLine={line} setValue={(v) => line.options.selectedOptionUid = v} />
                </Line>
                )}
            </React.Fragment>
          )
        }) }

        {canEdit && isEditMode &&
          <>
          <SaveButtonSeparateRow onClick={saveChanges}>
            {t('profile.saveButton')}
          </SaveButtonSeparateRow>         
        </>
        }

      </Container>
    </>
  );
}
