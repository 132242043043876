import { React, useState, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserLoginListTable from "./UserLoginListTable";
import api from "../../services/api";
import UserLoginPermissions from "../../services/userLogin.permissions.service"
import { Form } from 'react-bootstrap'

import Loading from '../Shared/Loading'

export default AdminUserLoginList ;

function AdminUserLoginList() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [onSaveLoading, setOnSaveLoading] = useState(false);
  const [userLoginList, setUserLoginList] = useState([]);

  useEffect(() => {
    function loadUserLoginList() {      
      api
        .get(`/settings/admin/user-logins`)
        .then((response) => {
          let converted = response.data.userLogins.map(x => {
            x.originalPermissions = x.permissions;
            x.canDoMetrology = UserLoginPermissions.canDoMetrology(x.permissions);
            x.isExpert = UserLoginPermissions.isExpert(x.permissions);
            x.isAdmin = UserLoginPermissions.isAdmin(x.permissions);
            x.canCreateUsers = UserLoginPermissions.canCreateUsers(x.permissions);
            x.canCreateUserGroups = UserLoginPermissions.canCreateUserGroups(x.permissions);
            return x;
          });

          setUserLoginList(converted);
          console.log(converted);
          setLoading(false);
        });
    };

    loadUserLoginList();

  }, []);

  const handleSetSelection = (data) => {
    //setIsSelectedRecords(data.length === 0);
    //selectedRecords.current = data;
  }  

  const onSaveDataClick = () => {
    setOnSaveLoading(true);

    let toSend = [];
    userLoginList.map(x => {
      x.permissions = UserLoginPermissions.combinePermissions(x.canCreateUsers, x.canCreateUserGroups, x.canDoMetrology, x.isExpert, x.isAdmin);
      if (x.permissions !== x.originalPermissions) toSend.push(x);
    });
    api
      .post(`/settings/admin/user-logins/save`, { userLogins: toSend })
      .then(response => {
          setOnSaveLoading(false);
      })

  }

  const columns = useMemo(
    () => [
      {
        // first group - TV Show
        Header: t('admin.userLoginList.header'),
        // First group columns
        columns: [
          {
            Header: "#",
            accessor: "number",
            className: "check-cell"
          },
          {
            Header: t('admin.userLoginList.table.firstName'),
            accessor: "firstName",
            //Cell: props => <Link to={{ pathname: `/user/${props.row.original.userUid}/record/${props.row.original.uid}` }}>{props.row.original.displayName}</Link>
          },
          {
            Header: t('admin.userLoginList.table.lastName'),
            accessor: "lastName",
          },
          {
            Header: t('admin.userLoginList.table.email'),
            accessor: "email",
            Cell: props => <Link to={{ pathname: `/admin/user-login/${props.row.original.uid}/settings/users` }}>{props.row.original.email}</Link>
          },          
          {
            Header: t('admin.userLoginList.table.createUsers'),
            accessor: "canCreateUsers",
            Cell: props => <Form.Check type='checkbox' onChange={() => {  props.row.original.canCreateUsers = !props.row.original.canCreateUsers; }} defaultChecked={props.row.original.canCreateUsers} />
          },
          {
            Header: t('admin.userLoginList.table.createUserGroups'),
            accessor: "canCreateUserGroups",
            Cell: props => <Form.Check type='checkbox' onChange={() => { props.row.original.canCreateUserGroups = !props.row.original.canCreateUserGroups; }} defaultChecked={props.row.original.canCreateUserGroups} />
          },
          {
            Header: t('admin.userLoginList.table.canDoMetrology'),
            accessor: "canDoMetrology",
            Cell: props => <Form.Check type='checkbox' onChange={() => { props.row.original.canDoMetrology = !props.row.original.canDoMetrology; }} defaultChecked={props.row.original.canDoMetrology}   />
          },
          {
            Header: t('admin.userLoginList.table.isExpert'),
            accessor: "isExpert",
            Cell: props => <Form.Check type='checkbox' onChange={() => { props.row.original.isExpert = !props.row.original.isExpert; }} defaultChecked={props.row.original.isExpert} />
          },
          {
            Header: t('admin.userLoginList.table.isAdmin'),
            accessor: "isAdmin",
            Cell: props => <Form.Check type='checkbox' onChange={() => { props.row.original.isAdmin = !props.row.original.isAdmin; }} defaultChecked={props.row.original.isAdmin} />
          }
                   
        ]
      }
    ],
    [t]
  );

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">{t('admin.userLoginList.header')}</h1>       
      </div>
      <div className="btn-toolbar mb-2 mb-md-0 float-end ">        
        <button type="button" className="btn btn-primary " onClick={onSaveDataClick}>   
          {onSaveLoading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          {t('admin.userLoginList.saveButton')}
        </button>
      </div>
      {loading ? <Loading /> :
        <UserLoginListTable columns={columns} data={userLoginList} setSelection={(selection) => handleSetSelection(selection)} />
      }

    </>
  )

}
