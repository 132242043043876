class TokenService {
  isUserLoggedIn() {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(user);
    return user !== undefined;
  }
  getUserUid() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.uid;
  }
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.refreshToken;
  }
  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.jwtToken;
  }
  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user === null) user = {};
    user.jwtToken = token;
    localStorage.setItem("user", JSON.stringify(user));

    this.clearTemporaryRefreshToken();
    this.callUpdateTokenCallbackForAndroidWebview(token)
  }

  callUpdateTokenCallbackForAndroidWebview(token) {    
    if (window.CardioplasterApp) {
      let rf = this.getCookie("refreshToken");
      window.CardioplasterApp?.updateToken(token, rf);
    }   
  }
  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
  setUser(user) {    
    localStorage.setItem("user", JSON.stringify(user));
  }
  removeUser() {
    localStorage.removeItem("user");
  }

  getTemporaryRefreshToken() {
    return localStorage.getItem("tempRefreshToken");
  }

  clearTemporaryRefreshToken() {
    return localStorage.removeItem("tempRefreshToken");
  }

  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}
export default new TokenService();