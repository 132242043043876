import { React, useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { dateAsNumbersWithoutSeconds } from '../../../utils/dateFormatter';

import api from "../../../services/api";

import Loading from '../Loading';

import TableFull from '../CommonTable/TableFull';

import styles from './UsersConsultationList.module.scss';

export default function UsersConsultationList({ consultationListBaseUrl, getPageUserRecordUrlTemplate }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  let navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        header: "#",
        maxWidth: '0.5rem',
        enableColumnFilter: false,
        cell: ({ row }) => (
          <div>
            {row.index + 1}
          </div>
        )
      },
      {
        header: t('consultation.user.list.userName'),        
        maxWidth: '3rem',
        accessorKey: "user.displayName"        
      },
      {
        header: t('consultation.user.list.place'),
        accessorKey: "userRecord.place",
      },
      {
        header: t('consultation.user.list.description'),
        accessorKey: "userRecord.description",
      },
      {
        header: t('consultation.user.list.recordDate'),
        accessorKey: "userRecord.date",
        cell: info => {
          return (
            <>
              {dateAsNumbersWithoutSeconds(info.getValue())}
            </>
          )
        }
      },
      {
        header: t('consultation.user.list.requestDate'),
        accessorKey: "requestDate",
        cell: info => {
          return (
            <>
              {dateAsNumbersWithoutSeconds(info.getValue())}
            </>
          )
        }
      },
      {
        header: t('consultation.user.list.expertName'),
        accessorKey: "expert.displayName"        
      },
      {
        header: t('consultation.user.list.acceptDate'),
        accessorKey: "acceptDate",
        cell: info => {
          return (
            <>
              {dateAsNumbersWithoutSeconds(info.getValue())}
            </>
          )
        }
      },
      {
        header: t('consultation.user.list.completeDate'),
        accessorKey: "completeDate",
        cell: info => {
          return (
            <>
              {dateAsNumbersWithoutSeconds(info.getValue())}
            </>
          )
        }
      },
      {
        header: t('consultation.user.list.state'),
        accessorKey: "state",
        cell: info => {
          return (
            <span className={`${info.row.original.stateId === 10 && styles.requestNew} ${info.row.original.stateId === 20 && styles.requestInProgress} ${info.row.original.stateId === 30 && styles.requestCompleted}`} >
              {info.getValue()}
            </span>
          )
        }
      }
    ],
    [t]
  );


  const loadRecords = () => {
    api
      .get(consultationListBaseUrl)
      .then((response) => {
        setData(response.data.items);

        setLoading(false);
      });
  };

  useEffect(() => {
    loadRecords();
  }, [consultationListBaseUrl]);

  const onRecordClickHandler = (row) => {
    console.log(row);
    if (getPageUserRecordUrlTemplate)
      navigate(getPageUserRecordUrlTemplate(row.user.uid, row.userRecord.uid));
  };

  if (loading)
    return <Loading />;

  return (
    <>

      <TableFull styles={styles} data={data} columns={columns} onRowClick={onRecordClickHandler} />     
    </>
  );
}