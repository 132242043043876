import { React, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { dateFormatter } from '../../utils/dateFormatter'
import Loading from '../Shared/Loading'
import api from "../../services/api";

import fileDownloadShow from "../../services/fileDownloadShow";
import * as CommonIcons from '../CommonIcons';

import TableFull from '../Shared/CommonTable/TableFull';

import styles from './DeviceList.module.scss'

export default function DevicesList({ getDeviceListUrl, baseUrl }) {
  const { t } = useTranslation(); 
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();

  const downloadOrShowFile = (e, target, row) => {
    e.stopPropagation();
    fileDownloadShow(`${getDeviceListUrl}/${row.uid}/protocol/latest/download`);
  };

  useEffect(() => {   
    loadDeviceList();
  }, []);

  const loadDeviceList = function () {   
    api
      .get(getDeviceListUrl)
      .then((response) => {
        setData(response.data.items);
        setLoading(false);
      });
  };

  const columns = useMemo(
    () =>
      [
        {
          header: t('metrology.deviceList.deviceType'),
          accessorKey: "deviceName",
          enableColumnFilter: false,
        },
        {
          header: t('metrology.deviceList.modelName'),
          accessorKey: "modelName",
          enableColumnFilter: false,
        },
        {
          header: t('metrology.deviceList.bluetoothAddress'),
          accessorKey: "bluetoothAddress",
          enableColumnFilter: false,
        },
        {
          header: t('metrology.deviceList.serialNumber'),
          accessorKey: "serialNumber",
          enableColumnFilter: false,
        },
        {
          id: 'lastProtocolDate-date',
          header: t('metrology.deviceList.lastRecordDate'),
          className: styles.dateCell,
          maxWidth: '3rem',
          accessorKey: "lastProtocolDate",
          enableColumnFilter: false,
          cell: info => <>{dateFormatter(info.getValue())}</>,
        },
        {
          header: t('metrology.deviceList.numberOfProtocols'),
          accessorKey: "numberOfProtocols",
          enableColumnFilter: false,
        },
        {
          id: 'lastProtocolDate-download',
          header: t('devices.table.header.download'),
          maxWidth: '3rem',
          className: `${styles.center}`,
          accessorKey: "lastProtocolDate",
          cell: info => {
            return (
              <>
                {info.getValue() && <button className={`${styles.btn}`} onClick={(e, target) => downloadOrShowFile(e, target, info.row.original)}><CommonIcons.DownloadButton /></button>}
              </>
            )
          }
        }
      ],
    [t]
  ); 

  const onRecordClickHandler = (record) => {    
    navigate(`${baseUrl}/${record.uid}/records/`);
  };

  if (loading)
    return <Loading />;

  return (
    <>
      <TableFull styles={styles} data={data} columns={columns} onRowClick={onRecordClickHandler} />
    </>
  )
}