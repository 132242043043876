import { useState, useEffect, useRef } from 'react';

import { ButtonGroup, ToggleButton, Row, Col, Button } from 'react-bootstrap'
import { EcgWebSocketReceiver } from './EcgWebSocketReceiver'
//import { EcgHighcharts } from './EcgHighcharts'
import { EcgHighchartsAsync } from './EcgHighchartsAsync'
//import { BreathingHighcharts } from './BreathingHighcharts'
import { BreathingHighchartsAsync } from './BreathingHighchartsAsync'
//import { TemperatureHighcharts } from './TemperatureHighcharts'
import { TemperatureHighchartsAsync } from './TemperatureHighchartsAsync'

//import { CommonHighcharts } from './CommonHighcharts'
import { CommonHighchartsAsync } from './CommonHighchartsAsync'

import { ProcessedCalculatedData } from '../Processed/ProcessedCalculatedData'

import DropdownDisplayTime from './DropdownDisplayTime'

import { useTranslation } from 'react-i18next';

import styles from './BaseMultipleChartsView.module.scss';

export function BaseMultipleChartsView({ dataList, realtimeDataUrl, chartDataTypes, asyncDataLoadUrl, processedDataReady, timeTypeChangeCallback, processedDataReceived }) {
  const { t } = useTranslation();

  const chartEcgRef = useRef(null);
  const chartBreathingRef = useRef(null);
  const chartTemperatureRef = useRef(null);
  const chartAccelX = useRef(null);
  const chartAccelY = useRef(null);
  const chartAccelZ = useRef(null);

  const chartRefArray = useRef([
    {
      dataTypeId: 1, ref: chartEcgRef, yAxis: [
        { title: { text: t('recordView.multiChart.seriesName.ecg') }, labels: { format: '{value} mV' }, opposite: false }]
    },
    {
      dataTypeId: 2,
      ref: chartBreathingRef,
      yAxis: [{
        title: { text: t('recordView.multiChart.seriesName.breathing') },
        labels: { format: '{value} Ohm' },
        opposite: false
      }],
      //copy:
      //{
      //  chart: chartEcgRef,
      //  yAxisId: 'y-breath',
      //  seriesName: t('recordView.multiChart.seriesName.breathing'),
      //  seriesId: 'series-breath',
      //  yAxis: [{ title: { text: t('recordView.multiChart.seriesName.breathing') }, labels: { format: '{value} Ohm' }, opposite: true, id: 'y-breath' }]
      //}
    },
    { dataTypeId: 3, ref: chartTemperatureRef, yAxis: [{ title: { text: t('recordView.multiChart.seriesName.temp') }, labels: { format: '{value} \u00B0C' }, opposite: false }] },
    //{ dataTypeId: 4, ref: chartAccelX, yAxis: [{ title: { text: t('recordView.multiChart.seriesName.accelX') }, labels: { format: '{value}' }, opposite: false }] },
    //{ dataTypeId: 5, ref: chartAccelY, yAxis: [{ title: { text: t('recordView.multiChart.seriesName.accelY') }, labels: { format: '{value}' }, opposite: false }] },
    //{ dataTypeId: 6, ref: chartAccelZ, yAxis: [{ title: { text: t('recordView.multiChart.seriesName.accelZ') }, labels: { format: '{value}' }, opposite: false }] },
  ]);  

  const [realtimeViewMode, setRealtimeViewMode] = useState(0); //0 - update navigator only, 1 - refresh (not used), 2 - live (scroll to latest)

  const [scrollToLatest, setScrollToLatest] = useState(false); 

  const realtimeModeRef = useRef(0);
  //const isChartInitialized = useRef(false);
  const chartDataArray = useRef([]);
  const [isOnlineTransmission, setOnlineTransmission] = useState(false);
  const [processedData, setProcessedData] = useState(null);
  const onFirstTransferOnly = useRef(true);

  useEffect(() => {    
    console.log('useEffect: ', dataList);    

    if (chartDataTypes.length > 0) {
      //init charts
      //console.log('init charts');
      chartDataTypes.map((item) => initChart(item));
    }

    if (dataList.items && dataList.items.length > 0) {
      //console.log('init data');
      //if (!isChartInitialized.current) {
        chartDataTypes.map((item) => addInitialData(item));
      //  isChartInitialized.current = true;
      //}
    }

  }, [dataList, chartDataTypes]);

  const getChartDataByType = (type) => {    
    let filtered = chartRefArray.current.find(x => x.dataTypeId === type);
    if (filtered)
      return filtered;      
   
    return null;
  };

  const initChart = (chartType) => {
    //console.log('chart for ', chartType, ' initialized');
    let chartRef = getChartDataByType(chartType);
    if (chartRef) {
      chartRef.ref.current.setupYAxis(chartRef.yAxis);
      if (chartRef.copy) {
        let copyChart = chartRef.copy.chart.current;
        if (chartRef.copy.yAxis)
          copyChart.setupYAxis(chartRef.copy.yAxis);
      }

      addDataToChart(chartType, { data: [] });
    }
  };

  const addDataToChart = (chartType, dataObject) => {
    let chartRef = getChartDataByType(chartType);
    if (chartRef) {
      //console.log('dataObject ', dataObject);

      if (dataObject.longRecord) {
        chartRef.ref.current.setInitialDataForLongSeries(dataObject.longRecord, `${asyncDataLoadUrl}?type=${chartType}`);


      } else {
        let data = dataObject.data;

        chartRef.ref.current.setInitialData(data);

        if (chartRef.copy) {
          let copyChart = chartRef.copy.chart.current;
          copyChart.setInitialData(data, { seriesId: chartRef.copy.seriesId, name: chartRef.copy.seriesName, yAxisId: chartRef.copy.yAxisId, color: 'green' });
        }

        if (realtimeDataUrl)
          setTimeout(() => chartRef.ref.current.scrollToEnd(), 100);
      }
    }
  };

  const addAnnotationsToEcgChart = (record) => {   
    let newAnnotations = { labels: [] };
    newAnnotations.labels = record.dataObject.map((item, index) =>
      ({
        point: {
          x: item.x,
          y: item.y,
          xAxis: 0,
          yAxis: 0
        },
        text: `R #${index}<br>RR:${item.rr}<br>HR:${item.hr}`
      }));

    chartEcgRef.current.addAnnotation(newAnnotations);
  }

  const addInitialData = function (chartType, index) {
    //if (!isChartInitialized.current)
    if (dataList.items.length > 0) {
      let dataByType = dataList.items.find(x => x.dataType == chartType);

      let dataArray = { dataType: chartType, data: [] };

      if (dataByType && (dataByType.data || dataByType.longRecord)) {
        if (dataByType.data)
          dataByType.data.map(x => dataArray.data.push([x[0], x[1]]));
        dataArray.longRecord = dataByType.longRecord;


        if (chartDataArray.current.find(x => x.dataType == chartType)) {
          chartDataArray.current = chartDataArray.current.filter(x => x.dataType != chartType);
        }
        chartDataArray.current.push(dataArray);
      }
    }

    //add existing data to the chart
    let filteredDataArray = chartDataArray.current.find(x => x.dataType == chartType);
    if (filteredDataArray) {
      addDataToChart(chartType, filteredDataArray);

      //if ECG and RR interval list exists, then add annotations to the chart
      if (chartType == 1) {
        let annotations = dataList.items.find(x => x.dataType == 101);
        if (annotations) addAnnotationsToEcgChart(annotations);
      }
    }
  };

  const newDataReceived = (data) => {
    //console.log(data);
    //initChart();
    //console.log('mode: ', realtimeModeRef.current);
    ////console.log('ecgChartDataArray: ', ecgChartDataArray.current);

    //console.log('data: ', data);

    if (onFirstTransferOnly.current) {
      setTimeout(() => setLiveViewMode(3), 500);
      onFirstTransferOnly.current = false;
    }

    setOnlineTransmission(true);

    data.items.map((item, index) => {
      let chartRef = getChartDataByType(item.dataType);
      if (chartRef && chartRef.ref && chartRef.ref.current) {
        //console.log(chartRef, item);
        chartRef.ref.current.addNewDataToChartLongSeries(item);

        //chartRef.ref.current.addNewDataToChart(item.data);

        //if (chartRef.copy) {
        //  let copyChart = chartRef.copy.chart.current;
        //  //setup axis here for a duplicate??
        //  copyChart.addNewDataToChart(item.data, chartRef.copy.seriesId);
        //}
      }
    });    

    setProcessedData(data.processed);
    if (processedDataReceived)
      processedDataReceived(data.processed);
  };

  const finalize = () => {
    setOnlineTransmission(false);
    console.log('Finished');
  };

  const setMode = (mode) => {
    setRealtimeViewMode(mode);
    realtimeModeRef.current = mode;
    chartRefArray.current.map(x => {
      if (x.ref && x.ref.current)
        x.ref.current.setDataViewMode(mode);
    });
  }

  const setContinuousMode = () => {
    setMode(0);
  };

  const setRefreshMode = () => {
    setMode(1);   
  }; 

  const setLiveViewMode = () => {
    setMode(2);
    //if (!scrollToLatest) {
    //  setMode(2);
    //  setScrollToLatest(true);
    //} else {
    //  setMode(0);
    //  setScrollToLatest(false);
    //}
  };

  const setChartDisplayTime = (mode) => {
    console.log('Switching mode to ', mode);
    if (timeTypeChangeCallback)
      timeTypeChangeCallback(mode);
    //chartEcgRef.current.setChartDisplayTimeMode(mode);
    //chartBreathingRef.current.setChartDisplayTimeMode(mode);
    //chartTemperatureRef.current.setChartDisplayTimeMode(mode);
  };

  return (
    <>

      {realtimeDataUrl &&
        <Row className={styles.bb}>
          <Col>
            <ProcessedCalculatedData processedData={processedData} />
          </Col>
        </Row>
      }

      {
        realtimeDataUrl &&
        <>
          { /*{t('recordView.realtime.viewMode.header') + ':  '}
          <ButtonGroup className="pull-right">
            <ToggleButton key={"0"} id={"id-0"} type="radio" variant='outline-warning' name="radio" value={0} checked={realtimeViewMode === 0} onChange={setContinuousMode}>
              {t('recordView.realtime.viewMode.continuous')}
            </ToggleButton>
            <ToggleButton key={"1"} id={"id-1"} type="radio" variant='outline-warning' name="radio" value={1} checked={realtimeViewMode === 1} onChange={setRefreshMode}>
              {t('recordView.realtime.viewMode.overwrite')}
            </ToggleButton>         
          </ButtonGroup> */}          
          {/*<span className="float-end">{t('recordView.realtime.state.header')}: {isOnlineTransmission && <span><i className="bi bi-circle-fill" style={{ "color": "limegreen" }} ></i> {t('recordView.realtime.state.online')}</span>}{!isOnlineTransmission && <span>{t('recordView.realtime.state.offline')}</span>}</span>*/}
          <Row className="mt-3">
            <Col>
              <span className="float-end">
                {isOnlineTransmission && <Button variant={scrollToLatest ? "primary" : "outline-primary"} onClick={setLiveViewMode}><i className="bi bi-circle-fill" style={{ "color": "limegreen" }} ></i> {t('recordView.realtime.state.live')}</Button>}
                {!isOnlineTransmission && <span>{t('recordView.realtime.state.offline')}</span>}
              </span>
            </Col>
          </Row>
      </>
      }

      {!realtimeDataUrl && <Row>
        <Col className="text-end mt-3">
          <DropdownDisplayTime onSelectionChangedCallback={(mode) => setChartDisplayTime(mode)} />
        </Col>
      </Row>}

      <Row>
        <Col>
          <span>{t('recordView.multiChart.chartHeader.ecg')}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          {/*<EcgHighcharts ref={chartEcgRef} />*/}
          <EcgHighchartsAsync ref={chartEcgRef} />
        </Col>
      </Row>
      

      {chartDataTypes.length > 1 && (
        <>
          <Row>
            <Col>
              <span>{t('recordView.multiChart.chartHeader.breathing')}</span>
              {/*<BreathingHighcharts ref={chartBreathingRef} seriesName={t('recordView.multiChart.seriesName.breathing')} />*/}
              <BreathingHighchartsAsync ref={chartBreathingRef} seriesName={t('recordView.multiChart.seriesName.breathing')} />
            </Col>
            <Col>
              <span>{t('recordView.multiChart.chartHeader.temp')}</span>
              {/*<TemperatureHighcharts ref={chartTemperatureRef} seriesName={t('recordView.multiChart.seriesName.temp')} />*/}
              <TemperatureHighchartsAsync ref={chartTemperatureRef} seriesName={t('recordView.multiChart.seriesName.temp')} />
            </Col>
          </Row>

          {/*
            <Row>
            <Col>
              <span>{t('recordView.multiChart.chartHeader.accelX')}</span>
              <CommonHighchartsAsync ref={chartAccelX} seriesName={t('recordView.multiChart.seriesName.accelX')} />
            </Col>
            <Col>
              <span>{t('recordView.multiChart.chartHeader.accelY')}</span>
              <CommonHighchartsAsync ref={chartAccelY} seriesName={t('recordView.multiChart.seriesName.accelY')} />
            </Col>
            <Col>
              <span>{t('recordView.multiChart.chartHeader.accelZ')}</span>
              <CommonHighchartsAsync ref={chartAccelZ} seriesName={t('recordView.multiChart.seriesName.accelZ')} />
            </Col>
          </Row>
          */}
        </>
      )}
      

      {/* <h3 style={{ display: 'none' }}>Hovering over {hoverData}</h3> */}
      {realtimeDataUrl && <EcgWebSocketReceiver realtimeDataUrl={realtimeDataUrl} receiveNewDataCallback={newDataReceived} finalizeCallback={finalize} />}
    </>
  )


}